import React from "react";
import { useState, useEffect } from "react";
import consultCep from "../../services/ConsultCep";
import { cellphoneMask, zipCodeMask } from "../../helpers/Masks";
import ConsultarService from "../../services/ConsultarService";
import { isServerError } from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";

interface Props {
  contributor_name?: string;
  is_update?: boolean;
  attendance_place: string
}

export default function ContributorForm({ contributor_name, is_update, attendance_place}: React.PropsWithChildren<Props>) {

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [uf, setUf] = useState("");
  const [city, setCity] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  const [contributorDocment, setContributorDocument] = useState("");
  const [loading, setLoading] = useState(false);
  const [requisition, setRequisition] = useState(false);

  useEffect(() => {
    var contributordata = (document.getElementById("contributorDocment") as HTMLInputElement).value.replace(/\D/g,'');
    setContributorDocument(contributordata);
  })

  const phoneMask = (phoneNumber : string) => {
    const phonemasked = cellphoneMask(phoneNumber).mask()
    setPhone(phonemasked)
  }

  const zipMaske = (zipCode : string) => {
    const zipCodeMasked = zipCodeMask(zipCode).mask()
    setZipCode(zipCodeMasked)
  }

  const validateEmail = () => {
    const validationemail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
    const inputField = (document.getElementById('emailrequired')as HTMLInputElement)
    if (validationemail){
      inputField.classList.remove('errorfield')
    }else{
      inputField.classList.add('errorfield')
    }
    return validationemail
  }

  const validatePhoneNumber = () => {
    const inputField = (document.getElementById('phoneinput')as HTMLInputElement)
    if (phone.length != 15){
      inputField.classList.add('errorfield')
    }else{
      inputField.classList.remove('errorfield')
    }
  }

  const validateZipCode = () => {
    const inputField = (document.getElementById('zipcoderequired')as HTMLInputElement)
    if (zipCode.length != 10){
      inputField.classList.add('errorfield')
    }else{
      inputField.classList.remove('errorfield')
    }
  };

  async function dataFromApi() {

    const zipCodeUnMasked = zipCode.replace(/\D/g,'');

    try{
      const response = await consultCep.get(`${zipCodeUnMasked}/json`)
      if (response.data.erro) {
        setStreet("");
        setUf("");
        setCity("");
        setNeighborhood("")
      }else {
        setStreet(response.data.logradouro);
        setUf(response.data.uf);
        setCity(response.data.localidade);
        setNeighborhood(response.data.bairro)
      }
    }catch{
      setStreet("");
      setUf("");
      setCity("");
      setNeighborhood("")
    }
  };

  var enableButton = false

  const setButtonStatus = () => {
    enableButton = true
  }

  const validateButton = () => {
    if(email && phone && phone.length == 15 && zipCode && zipCode.length == 10 && street && number && uf && city && neighborhood && validateEmail()){
      var nomeClass = 'buttonyelow';
      setButtonStatus();
    } else {
      var nomeClass = 'buttongray';
    }

    return(
      <div className="divbutton">
        {loading ?
          <button type='submit' id='submitButton' className={nomeClass} onClick={handleSubmit}>
            <FontAwesomeIcon icon={faCircleNotch} spin className="fa-spin-fast" />
          </button> :
        <button type='submit' className={nomeClass} onClick={handleSubmit}>Continuar</button> }
      </div>
    );
  }

  const simpleButton = () => {
    var nomeClass = 'buttongray'
    return (
      <div className="divbutton">
        {loading ?
          <button type='submit' id='submitButton' className={nomeClass}>
            <FontAwesomeIcon icon={faCircleNotch} spin className="fa-spin-fast" />
          </button> :
        <button type='submit' className={nomeClass}>Continuar</button> }
      </div>
    )
  }

  const  zipCodeFunctions = () => {
    dataFromApi();
    validateZipCode();
  }

  if (is_update == null || is_update) {
    return (<></>);
  }

  const handleSubmit = async () => {
    if(enableButton) {
      const zipCodeUnMasked = zipCode.replace(/\D/g,'');
      const contributor_document = contributorDocment;
      const phoneUnmask = phone.replace(/\D+/g, '')
      const contributor_attendance_histories = {'attendance_place': attendance_place,
                                            'attendant': 'Assitente Virtual',
                                            'attendance_subject': 'registration_update'};
      const contributor_contact_histories = {'telephone': phoneUnmask,
                                              'cell_phone': phoneUnmask,
                                              'email': email,
                                              'current_contact': true};
      const address = {'street': street,
                        'street_number': number,
                        'neighborhood': neighborhood,
                        'city': city,
                        'state': uf,
                        'cep': zipCodeUnMasked,
                        'address_complement': complement,
                        'main': true}
      const attendanceData = {contributor_document, contributor_attendance_histories}
      const contactData = {contributor_document, contributor_contact_histories}
      const adressData = {contributor_document, address}


      setLoading(true);
      setRequisition(true);
      var contactSuccessMessage
      var addressSuccessMessage
      var attendanceSuccessMessage

      await ConsultarService
        .contributorNewContact(contactData)
        .then((response) => {
          contactSuccessMessage = response.status
        })

      await ConsultarService
        .contributorAddress(adressData)
        .then((response) => {
          addressSuccessMessage = response.status
        })

      await ConsultarService
        .attendanceRegistration(attendanceData)
        .then((response) => {
          attendanceSuccessMessage = response.status
        })

      setLoading(false);

      if (addressSuccessMessage  && contactSuccessMessage && attendanceSuccessMessage == 201){
        const successSpanMessage = (document.getElementById('successmessageid') as HTMLAnchorElement);
        successSpanMessage.removeAttribute('hidden')
        const errorSpanMessage = (document.getElementById('errormessageid') as HTMLAnchorElement);
        errorSpanMessage.setAttribute('hidden', 'true')
        const reloadSearch = () => {
          (document.getElementById('searchbuttonid') as HTMLAnchorElement).click();
        };
        setTimeout(reloadSearch, 1000 * 3);
      }else {
        setRequisition(false);
        const errorSpanMessage = (document.getElementById('errormessageid') as HTMLAnchorElement);
        errorSpanMessage.removeAttribute('hidden')
        const reloadPage = () => {
          document.location.reload();
        };
        setTimeout(reloadPage, 1000 * 4);
      }
    }
  }

  return (
    <div className="content">
      <div hidden={true} className="servermessage" id="successmessageid">
        <span>Dados de contribuinte atualizados.</span>
      </div>
      <div hidden={true} className="servermessage" id="errormessageid">
        <span>Não foi possível atualizar seus dados no momento. Por favor, tente novamente mais tarde.</span>
      </div>
      <div className="toptext">
        <p><strong className="attention">ATENÇÃO:</strong> para prosseguirmos com a consulta, atualize seus dados cadastrais. Preencha
          os campos abaixo e clique em continuar
        </p>
      </div>
      <div className="form">
        <div>
          <span className="titlefields" id="contributorname">Nome Completo <span className="required">*</span></span><br></br>
          <input type="text" className="majorfields" readOnly={true} value={contributor_name}></input>
        </div>
        <div>
          <span>E-mail <span className="required">*</span></span><br></br>
          <input id="emailrequired" type="email" className="majorfields" onChange={(e) => setEmail(e.target.value)} onBlur={validateEmail}></input>
        </div>
        <div>
          <span>Telefone de contato (Whatsapp) <span className="required">*</span></span><br></br>
          <input id="phoneinput"
            type="text"
            className="majorfields"
            onChange={(e) => {phoneMask(e.target.value)}}
            onBlur={validatePhoneNumber}
            value={phone}
            minLength={15}
            maxLength={15}>
          </input>
        </div>
        <span className="linebraker"></span>
        <div>
          <span>CEP <span className="required">*</span></span><br></br>
          <input id="zipcoderequired"
            type="text" onBlur={zipCodeFunctions}
            onChange={(e) => {zipMaske(e.target.value)}}
            value={zipCode} minLength={10} maxLength={10}>
          </input>
        </div>
        <div>
          <span>Rua <span className="required">*</span></span><br></br>
          <input id="inputrequired" type="text" className="majorfields" onChange={(e) => setStreet(e.target.value)} value={street}></input>
        </div>
        <div>
          <span>Número <span className="required">*</span></span><br></br>
          <input id="inputrequired" type="number" onChange={(e) => setNumber(e.target.value)}></input>
        </div>
        <div>
          <span>Complemento</span><br></br>
          <input type="text" onChange={(e) => setComplement(e.target.value)}></input>
        </div>
        <div>
          <span>Estado <span className="required">*</span></span><br></br>
          <input id="inputrequired" type="text" className="majorfields" onChange={(e) => setUf(e.target.value)} value={uf}></input>
        </div>
        <div>
          <span>Cidade <span className="required">*</span></span><br></br>
          <input id="inputrequired" type="text" className="majorfields" onChange={(e) => setCity(e.target.value)} value={city}></input>
        </div>
        <div>
          <span>Bairro <span className="required">*</span></span><br></br>
          <input id="inputrequired" type="text" className="majorfields" onChange={(e) => setNeighborhood(e.target.value)} value={neighborhood}></input>
        </div>
      </div>
      <div className="bottontext">
        <span>O preencimento dos dados com <span className="required"> * </span> acima são obrigatórios.</span>
        <span> Os dados preenchidos devem ser referentes ao CPF/CNPJ consultado.</span>
      </div>
      {requisition ? simpleButton() : validateButton()}
    </div>
  )
}
